import React, { Component } from 'react';
import { Accordion, Icon, Label, Image } from 'semantic-ui-react';

import AddFacebookEvent from '../AddFacebookEvents/AddFacebookEvent';
import EventForm from '../EventForm';
import UploadForm from '../UploadForm';
import iconLogo from '../../assets/Icon-Blue.jpg';

import './addStyles.css';

export default class Add extends Component {
	state = {
		activeIndex: 0,
		loading: false,
	};

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	uploading = (uploading) => {
		this.setState({
			loading: uploading
		});
	};

	render() {
		const { activeIndex } = this.state;

		if (this.state.loading) {
			return (
				<Image src={iconLogo} size='tiny' className='App-logo' />
			);
		}

		return (
			<div className="container addEvents">
				<Accordion fluid styled>
					<Accordion.Title
						className="accordionTitle"
						active={activeIndex === 0}
						index={0}
						onClick={this.handleClick}
					>
						<Label color="teal" size="large">
							<Icon className="whiteIcon" name="table" />
							Upload a Spreadsheet
						</Label>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 0}>
						<UploadForm
							token={this.state.bearerToken}
							uploading={this.uploading}
						/>
					</Accordion.Content>

					<Accordion.Title
						className="accordionTitle"
						active={activeIndex === 1}
						index={1}
						onClick={this.handleClick}
					>
						<Label color="teal" size="large">
							<Icon className="whiteIcon" name="clipboard outline" />
							Fill out a Form
						</Label>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 1}>
						<EventForm
							token={this.state.bearerToken}
						/>
					</Accordion.Content>

					<Accordion.Title
						className="accordionTitle"
						active={activeIndex === 2}
						index={2}
						onClick={this.handleClick}
					>
						<Label color="teal" size="large">
							<Icon className="whiteIcon" name="facebook f" />
							Add from Facebook
						</Label>
					</Accordion.Title>
					<Accordion.Content active={activeIndex === 2}>
						<AddFacebookEvent />
					</Accordion.Content>
				</Accordion>
			</div>
		);
	}
}
